import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FiShoppingBag } from "react-icons/fi"; 
import { RiContactsLine } from "react-icons/ri";
import { RiPagesLine } from "react-icons/ri";
import { SiBrandfolder } from "react-icons/si";
import { MdOutlineFeaturedPlayList } from "react-icons/md";
import { MdOutlineCategory } from "react-icons/md";
import { IoNotificationsOutline } from "react-icons/io5";
import { BiDice2 } from "react-icons/bi";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { FaQuestion } from "react-icons/fa";
import { FaRegObjectGroup } from "react-icons/fa";
import { MdOutlineSettings } from "react-icons/md";
import { RiAuctionLine } from "react-icons/ri";
import { MdOutlineShoppingCart } from "react-icons/md";
import { SiBattledotnet } from "react-icons/si";
import { GrContact } from "react-icons/gr";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FcDataConfiguration } from "react-icons/fc";

export const links = [
  {
    title: "Dashboard",
    links: [
      {
        name: "dashboard",
        path: "dashboard",
        icon: <FiShoppingBag />,
      },
    ],
  },

  {
    title: "Pages",
    links: [
      // {
      //   name: "orders",
      //   path: "orders",
      //   icon: <AiOutlineShoppingCart />,
      // },
      {
        name: "Users",
        path: "users",
        icon: <RiContactsLine />,
      },
      {
        name: "Admin",
        path: "admin",
        icon: <MdOutlineAdminPanelSettings />,
      },
    ],
  },
  {
    title: "catalog",
    links: [
      {
        name: "Category",
        path: "category",
        icon: <MdOutlineCategory />,
      },
      {
        name: "Brand",
        path: "brand",
        icon: <SiBrandfolder />,
      },
      {
        name: "Product",
        path: "product",
        icon: <AiOutlineShoppingCart />,
      },
            {
        name: "Attribute",
        path: "attribute",
        icon: <MdOutlineFeaturedPlayList />,
      },
      {
        name: "Attribute Group",
        path: "attribute-group",
        icon: <FaRegObjectGroup />,
      },
  
    ],
  },
  {
    title: "Auctions ",
    links: [
      {
        name: "Bid Type",
        path: "bidOption",
        icon: <BiDice2 />,
      },
      {
        name: "Auction",
        path: "bidAuction",
        icon: <RiAuctionLine />,
      },
      {
        name: "Bid",
        path: "auctionBid",
        icon: <SiBrandfolder />,
      },
      {
        name: "Bid Battle",
        path: "bid-battle",
        icon: <SiBattledotnet />,
      },
      {
        name: "Order",
        path: "all-orders",
        icon: <MdOutlineShoppingCart />,
      },
      
    ],
  },
  {
    title: "Membership ",
    links: [
      {
        name: "Membership Plan",
        path: "membership",
        icon: <RiMoneyDollarBoxLine />,
      },  
    ],
  },
  {
    title: "CMS",
    links: [
      {
        name: "CMS",
        path: "cms",
        icon: <RiPagesLine />,
      },
      {
        name: "Notification",
        path: "notification",
        icon: <IoNotificationsOutline />,
      },
      {
        name: "FAQ",
        path: "faq",
        icon: <FaQuestion />,
      },
      {
        name: "ContactUs",
        path: "contactUs",
        icon: <GrContact />,
      },
    ],
  },
  {
    title: "CONFIGURATION",
    links: [
      {
        name: "Configuration",
        path: "configuration/add-configuration",
        icon: <FcDataConfiguration />,
      },
      // {
      //   name: "Notification",
      //   path: "notification",
      //   icon: <IoNotificationsOutline />,
      // },
      // {
      //   name: "FAQ",
      //   path: "faq",
      //   icon: <FaQuestion />,
      // },
    ],
  },
  // {
  //   title: "Apps",
  //   links: [
  //     {
  //       name: "calendar",
  //       icon: <AiOutlineCalendar />,
  //     },
  //     {
  //       name: "kanban",
  //       icon: <BsKanban />,
  //     },
  //     {
  //       name: "editor",
  //       icon: <FiEdit />,
  //     },
  //     {
  //       name: "color-picker",
  //       icon: <BiColorFill />,
  //     },
  //   ],
  // },
  // {
  //   title: "Charts",
  //   links: [
  //     {
  //       name: "line",
  //       icon: <AiOutlineStock />,
  //     },
  //     {
  //       name: "area",
  //       icon: <AiOutlineAreaChart />,
  //     },

  //     {
  //       name: "bar",
  //       icon: <AiOutlineBarChart />,
  //     },
  //     {
  //       name: "pie",
  //       icon: <FiPieChart />,
  //     },
  //     {
  //       name: "financial",
  //       icon: <RiStockLine />,
  //     },
  //     {
  //       name: "color-mapping",
  //       icon: <BsBarChart />,
  //     },
  //     {
  //       name: "pyramid",
  //       icon: <GiLouvrePyramid />,
  //     },
  //     {
  //       name: "stacked",
  //       icon: <AiOutlineBarChart />,
  //     },
  //   ],
  // },
];